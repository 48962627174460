import React from 'react'
import Head from 'next/head'
import Link from 'next/link'
import { Button, Type } from '@/components/common'
import useAuth from '@/services/hooks/useAuth'
import {
  LANDING_HERO,
  LANDING_HERO_DESKTOP,
  LANDING_HERO_MOBILE,
} from '../../../../../constants/images'

const LandingPageHero: React.FC = () => {
  const { user, startAuth } = useAuth()

  return (
    <div className="flex flex-col bg-core-gray-950 bg-hero-mobile bg-cover bg-center bg-no-repeat sm:bg-hero md:flex-row">
      <Head>
        <link
          as="image"
          rel="prefetch"
          href={LANDING_HERO_MOBILE}
          media="(max-width: 639px)"
        />
        <link
          as="image"
          rel="prefetch"
          href={LANDING_HERO}
          media="(min-width: 640px) and (max-width: 1279px)"
        />
        <link
          as="image"
          rel="prefetch"
          href={LANDING_HERO_DESKTOP}
          media="(min-width: 1280px)"
        />
      </Head>
      <div className="mx-auto flex w-full max-w-[1168px] flex-col items-center justify-center px-4 pb-10 pt-20">
        <div className="mx-auto mb-8 max-w-[334px] text-center md:mb-6 md:max-w-[624px] lg:max-w-[651px] xl:mb-8 xl:max-w-[711px]">
          <Type
            as="h2"
            variant={['heading-sm', 'md:heading-md', 'xl:heading-lg']}
            className="mb-4 font-bold text-white md:mb-6 xl:mb-8"
            data-cy="main-tagline"
          >
            Create and Invest in Stories that Amplify Light
          </Type>
          <Type as="p" variant="paragraph-lg" className="text-core-white">
            Join our community of filmmakers and investors by supporting
            uplifting stories that are changing the world.
          </Type>
        </div>

        <div className="flex w-full flex-col items-center gap-4 md:w-auto md:flex-row">
          {user === null && (
            <Button
              color="white"
              className="w-full max-w-sm md:w-auto"
              data-cy="create-investment-account-button"
              onClick={() => startAuth()}
            >
              Create Investor Account
            </Button>
          )}
          <Link href="/apply" className="w-full max-w-sm md:w-auto">
            <Button
              variant="secondary"
              color="white"
              className="w-full"
              data-cy="apply-to-fund-your-project-button"
            >
              Fund Your Project
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LandingPageHero
